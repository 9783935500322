<template>
  <div class="reapirList">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.reapirList {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg,
    rgba(114, 171, 253, 0) 0%,
    #568efc 70%,
    #3e73fb 100%
  );
  background-size: 100% 12.1875rem;
  background-repeat: no-repeat;
}
</style>